import Vue from 'vue';
import App from './App.vue';
import { vsChip, vsIcon } from 'vuesax';
import 'vuesax/dist/vuesax.css';
import router from './router';
import store from './store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueSimpleAlert from 'vue-simple-alert';
import FlashMessage from '@smartweb/vue-flash-message';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'flatpickr/dist/flatpickr.css';
import DatePicker from 'vue2-datepicker';
import {
  LoopingRhombusesSpinner,
  CirclesToRhombusesSpinner,
  HalfCircleSpinner,
  HollowDotsSpinner
} from 'epic-spinners';
// import { ContentLoader } from 'vue-content-loader';
import PortalVue from 'portal-vue';
import '@/assets/sass/custom.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue2-datepicker/index.css';
import vuetify from '@/plugins/vuetify'; // path to vuetify export
import './imports/font-awesome-pro'

import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
Vue.component('multiselect', () =>
  import(/* webpackChunkName: "vue-multiselect" */ 'vue-multiselect')
);
Vue.component('LoopingRhombusesSpinner', LoopingRhombusesSpinner);
Vue.component('CirclesToRhombusesSpinner', CirclesToRhombusesSpinner);
Vue.component('HalfCircleSpinner', HalfCircleSpinner);
Vue.component('HollowDotsSpinner', HollowDotsSpinner);
import VueApexCharts from 'vue-apexcharts';


Vue.use(VueMoment, {
  moment
});
Vue.use(PortalVue);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSimpleAlert);
Vue.use(DatePicker);
Vue.use(VueSweetalert2);
Vue.use(vsChip);
Vue.use(vsIcon);
Vue.use(FlashMessage, { strategy: 'multiple' });
Vue.use(VueApexCharts);

//Vue.use(FlashMessage);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
