import axios from 'axios';
import domain from '@/environment';
import SettingFilialeTh from '../../models/FilialeTh';
let requestGetSettingFilialeTh = null;
let requestRenameFile = null;
let cancelRequestAllFilialeListe = null;
let cancelRequestSettingOrganismeTh = null;
const state = {
  loadingSettingFilialeTh: false,
  errorSettingFilialeTh: null,
  SettingFilialeTh: [],
  responsableFilialeFreeTh: [],
  typeSocieteSupportth: [],
  listeFilialesLocateur: [],
  ListeSocieteSupportth: [],
  SequencesFacturesFields: [],
  masterFilialeList: [],
  societeOfMasterFiliale: [],
  totalRowFiliale: 0,
  SettingOrganismeTh: [],
  errorSocieteMasterFiliale: null
};
const getters = {
  getSettingFilialesTh: state => state.SettingFilialeTh,
  getLoadingSettingFilialeTh: state => state.loadingSettingFilialeTh,
  getErrorSettingFilialeTh: state => state.errorSettingFilialeTh,
  getResponsableFilialeFreeTh: state => state.responsableFilialeFreeTh,
  getTypeSocieteSupportTh: state => state.typeSocieteSupportth,
  getSequencesFacturesFields: state => state.SequencesFacturesFields,
  getMasterFilialeList: state => state.masterFilialeList,
  getSocieteOfMasterFiliale: state => state.societeOfMasterFiliale,
  getTotalFiliale: state => state.totalRowFiliale,
  getListFilialesLocateur: state => state.listeFilialesLocateur,
  SettingOrganismeTh: state => state.SettingOrganismeTh,
  getErrorsSocieteMasterFiliale: state => state.errorSocieteMasterFiliale
};
const mutations = {
  SET_LOADING_SETTING_FILIALE_TH(state, payload) {
    state.loadingSettingFilialeTh = payload;
  },
  SET_ERROR_SETTING_FILIALE_TH(state, payload) {
    state.errorSettingFilialeTh = payload;
  },
  NEW_SETTING_FILIALE_TH(state, payload) {
    state.SettingFilialeTh.unshift(SettingFilialeTh.create(payload));
  },
  SET_SETTING_FILIALE_TH(state, payload) {
    state.SettingFilialeTh = payload.map(item => SettingFilialeTh.create(item));
  },
  SET_SETTING_ORGANISME_TH(state, payload) {
    state.SettingOrganismeTh = payload;
  },
  DELETE_FILIALE_SETTING_TH(state, payload) {
    for (let index = 0; index < state.SettingFilialeTh.length; index++) {
      if (state.SettingFilialeTh[index].id == payload.id) {
        state.SettingFilialeTh.splice(index, 1);
      }
    }
  },
  UPDATE_FILIALE_SETTING(state, payload) {
    payload.old.update(payload.response);
  },
  SET_SETTING_RESPONSABLE_FILIALE_TH(state, payload) {
    state.responsableFilialeFreeTh = payload;
  },
  SET_SETTING_TYPE_SOCIETE_SUPPORT(state, payload) {
    let data = payload;

    data.forEach(function (item) {
      const select = { value: item.value, text: item.text };
      state.typeSocieteSupportth.push(select);
    });
  },

  SET_FILE_FILIALE_NAME(state, payload) {
    payload.item.files[payload.index].name = payload.response.name;
    payload.item.files[payload.index].link = payload.response.link;
  },
  SET_SEQUENCE_FACTURE_FIELDS(state, payload) {
    state.SequencesFacturesFields = payload;
  },
  SET_LIST_MASTER_FILIALES(state, payload) {
    state.masterFilialeList = payload;
  },
  SET_LIST_SOCIETE_OF_MASTER_FILIALE(state, payload) {
    state.societeOfMasterFiliale = payload;
  },
  SET_RESTAURER_SOCIETE(state, payload) {
    const index = state.SettingFilialeTh.findIndex(
      element => element.id === payload
    );
    state.SettingFilialeTh.splice(index, 1);
  },

  SET_TOTAL_ROWS(state, payload) {
    state.totalRowFiliale = payload;
  },

  SET_FILIALE_LOCATEUR(state, payload) {
    state.listeFilialesLocateur = payload.filter(
      item => item.type != 'particulier'
    );
  },
  SET_ERROR_SOCIETE_MASTER_FILIALES(state, payload) {
    state.errorSocieteMasterFiliale = payload;
  }
};
const actions = {
  async getAllSocieteOfMasterFiliales({ commit }, payload) {
    commit('SET_ERROR_SOCIETE_MASTER_FILIALES', null);
    try {
      const response = await axios.get(
        domain + `/api/filiale/societe_of_master?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_SOCIETE_OF_MASTER_FILIALE', response.data);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SOCIETE_MASTER_FILIALES', error.response.data);
      } else {
        commit('SET_ERROR_SOCIETE_MASTER_FILIALES', 'Une erreur est survenue');
      }
      return false;
    }
  },
  async restoreFiliale({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/filiale/restore_filiale`,
        { id: payload },
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_RESTAURER_SOCIETE', payload);
      return true;
    } catch {
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  async getAllMasterFilials({ commit }, payload) {
    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    try {
      const response = await axios.get(domain + `/api/filiale/master_filiale`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_LIST_MASTER_FILIALES', response.data.data);
      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  async AddSettingFilialeTh({ commit }, payload) {
    const params = new FormData();
    if (payload?.name != null) {
      params.append('name', payload.name);
    }
    if (payload?.type != null) {
      params.append('type', payload.type);
    }
    if (payload?.is_societe_support != null) {
      params.append('is_societe_support', payload.is_societe_support);
    }
    if (payload?.abreviation != null) {
      params.append('abreviation', payload.abreviation);
    }
    if (payload?.couleur != null) {
      params.append('couleur', payload.couleur);
    }
    if (payload?.type_societe != null) {
      params.append('type_societe', payload.type_societe);
    }
    if (payload.prenom && payload.prenom != null) {
      params.append('prenom', payload.prenom);
    }
    if (payload.pixel_id && payload.pixel_id != null) {
      params.append('pixel_id', payload.pixel_id);
    }
    if (payload.nom_famille && payload.nom_famille != null) {
      params.append('nom_famille', payload.nom_famille);
    }
    if (payload.civilite && payload.civilite != null) {
      params.append('civilite', payload.civilite);
    }
    if (payload?.siren_value != null) {
      params.append('siren_value', payload.siren_value);
    }
    if (payload?.tva_value != null) {
      params.append('tva_value', payload.tva_value);
    }
    if (payload.iban && payload.iban != null) {
      params.append('iban', payload.iban);
    }
    if (payload.swift && payload.swift != null) {
      params.append('swift', payload.swift);
    }
    if (payload.code_postal && payload.code_postal != null) {
      params.append('code_postal', payload.code_postal);
    }
    if (payload.site_internet && payload.site_internet != null) {
      params.append('site_internet', payload.site_internet);
    }
    if (payload.ville && payload.ville != null) {
      params.append('ville', payload.ville);
    }
    if (payload.pays && payload.pays != null) {
      params.append('pays', payload.pays);
    }
    if (payload.banque && payload.banque != null) {
      params.append('banque', payload.banque);
    }
    if (payload.fax && payload.fax != null) {
      params.append('fax', payload.fax);
    }
    if (payload?.in_configs) {
      params.append('in_configs', payload.in_configs);
    }
    if (payload.telephone && payload.telephone != null) {
      params.append('telephone', payload.telephone);
    }
    if (payload.description && payload.description != null) {
      params.append('description', payload.description);
    }
    if (payload.telephone_portable && payload.telephone_portable != null) {
      params.append('telephone_portable', payload.telephone_portable);
    }
    if (payload.rcs && payload.rcs != null) {
      params.append('rcs', payload.rcs);
    }
    if (payload.payment_condition && payload.payment_condition != null) {
      params.append('payment_condition', payload.payment_condition);
    }
    if (payload.capital && payload.capital != null) {
      params.append('capital', payload.capital);
    }
    if (payload.footer_type && payload.footer_type != null) {
      params.append('footer_type', payload.footer_type);
    }
    if (payload.footer && payload.footer != null) {
      params.append('footer', payload.footer);
    }
    if (payload.payment_type && payload.payment_type != null) {
      params.append('payment_type', payload.payment_type);
    }
    if (payload.responsables && payload.responsables.length) {
      for (let i = 0; i < payload.responsables.length; i++) {
        params.append('responsables[' + i + ']', payload.responsables[i]);
      }
    }
    if (payload?.start_day != null) {
      params.append('start_day', payload.start_day);
    }
    if (payload?.start_month != null) {
      params.append('start_month', payload.start_month);
    }
    if (payload?.start_year != null) {
      params.append('start_year', payload.start_year);
    }
    if (payload?.end_day != null) {
      params.append('end_day', payload.end_day);
    }
    if (payload?.end_month != null) {
      params.append('end_month', payload.end_month);
    }
    if (payload?.end_year != null) {
      params.append('end_year', payload.end_year);
    }
    if (payload?.masterFiliales?.length) {
      for (let i = 0; i < payload?.masterFiliales?.length; i++) {
        params.append(
          'masterFiliales[' + i + '][master_id]',
          payload.masterFiliales[i].master_id
        );
        params.append(
          'masterFiliales[' + i + '][date_debut]',
          payload.masterFiliales[i].date_debut
        );
        params.append(
          'masterFiliales[' + i + '][date_fin]',
          payload.masterFiliales[i].date_fin
        );
      }
    }

    if (payload.siret == null) {
      params.append('siret', '');
    } else {
      params.append('siret', payload.siret);
    }
    if (payload.adresse == null) {
      params.append('adresse', '');
    } else {
      params.append('adresse', payload.adresse);
    }
    if (payload.email == null) {
      params.append('email', '');
    } else {
      params.append('email', payload.email);
    }
    if (payload.volant && payload.volant == true) {
      params.append('responsable_depot', payload.responsable_depot);
      params.append('volant', payload.volant);
    } else {
      let index = 0;
      payload.depots.forEach((element, key) => {
        if (element) {
          params.append('depots[' + index + '][id]', element.id);
          params.append(
            'depots[' + index + '][responsable_depot]',
            element.responsable_depot
          );
          params.append(
            'depots[' + index + '][filiale_id]',
            element.filiale_id
          );
          params.append('depots[' + index + '][depot]', element.depot);
        }
        index++;
      });
    }
    if (payload.image) {
      params.append('logo', payload.image);
    }
    if (payload.filiale?.files && payload.filiale.files.length) {
      let index = 0;
      payload.filiale.files.forEach((element, key) => {
        if (element) {
          params.append('files[' + index + '][file]', element);
          params.append('files[' + index + '][name]', element.name_file);
        }
        index++;
      });
    }
    if (payload.filiale?.type_societe_support) {
      params.append(
        'type_societe_support',
        payload.filiale?.type_societe_support
      );
    }
    if (payload.filiale?.is_societe_support) {
      params.append('is_societe_support', payload.filiale.is_societe_support);
    }
    //ACHAT
    if (payload?.achat?.journal != null) {
      params.append('achat[journal]', payload?.achat.journal);
    }
    if (payload?.achat?.marchandise != null) {
      params.append('achat[marchandise]', payload?.achat.marchandise);
    }
    if (payload?.achat?.prestation != null) {
      params.append('achat[prestation]', payload?.achat.prestation);
    }
    //VENTE
    if (payload?.vente?.journal != null) {
      params.append('vente[journal]', payload?.vente.journal);
    }
    if (payload?.vente?.marchandise != null) {
      params.append('vente[marchandise]', payload?.vente.marchandise);
    }
    if (payload?.vente?.prestation != null) {
      params.append('vente[compte]', payload?.vente.prestation);
    }
    //COMPTE GENERATE
    if (payload?.customer != null) {
      params.append('customer', payload.customer);
    }
    if (payload?.supplier != null) {
      params.append('supplier', payload.supplier);
    }
    //IS DETAILED
    params.append('is_detailed', payload.is_detailed);
    params.append('is_detailed_ttc', payload.is_detailed_ttc);
    params.append('is_detailed_reste_charge', payload.is_detailed_reste_charge);
    //PRIME
    if (payload?.prime?.compte != null) {
      params.append('prime[compte]', payload.prime.compte);
    }
    if (payload?.prime?.journal != null) {
      params.append('prime[journal]', payload.prime.journal);
    }

    if (payload.tva && payload.tva.length) {
      for (let i = 0; i < payload.tva.length; i++) {
        params.append('tva[' + i + '][valeur]', payload.tva[i].valeur);
        params.append(
          'tva[' + i + '][compte_vente]',
          payload.tva[i].compte_vente == null ||
            payload.tva[i].compte_vente == ''
            ? null
            : payload.tva[i].compte_vente
        );
        params.append(
          'tva[' + i + '][compte_achat]',
          payload.tva[i].compte_achat == null ||
            payload.tva[i].compte_achat == ''
            ? null
            : payload.tva[i].compte_achat
        );
        params.append('tva[' + i + '][is_active]', payload.tva[i].is_active);
        params.append(
          'tva[' + i + '][compte_marchandise]',
          payload.tva[i].compte_marchandise
        );
        params.append(
          'tva[' + i + '][compte_prestation]',
          payload.tva[i].compte_prestation
        );
      }
    }
    try {
      const response = await axios.post(
        domain + `/api/filiale/create_filiale`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
      commit('NEW_SETTING_FILIALE_TH', response.data.data);
      return {
        success: true
      };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return {
          success: false,
          error: data
        };
      } else if (error?.response?.data?.error) {
        return {
          success: false,
          error: error?.response?.data?.error
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async getSettingFilialeTh({ commit }, payload) {
    const params = {
      type: payload?.type,
      type_societe: payload?.type_societe,
      deleted: payload?.deleted,
      search: payload?.search,
      page: payload?.page,
      per_page: payload?.per_page
    };

    commit('SET_ERROR_SETTING_FILIALE_TH', null);
    commit('SET_LOADING_SETTING_FILIALE_TH', true);

    try {
      if (requestGetSettingFilialeTh) {
        requestGetSettingFilialeTh.cancel('cancel-request');
      }
      requestGetSettingFilialeTh = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/filiale/all_filiale`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestGetSettingFilialeTh.token
      });

      if (payload?.page != null) {
        await commit('SET_SETTING_FILIALE_TH', response.data.data.data);
        await commit('SET_TOTAL_ROWS', response.data.data.total);
      } else {
        await commit('SET_SETTING_FILIALE_TH', response.data.data);
      }

      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        await commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else if (error.message !== 'cancel-request') {
        await commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return false;
    }
  },

  async deleteSettingFilialeTh({ commit }, payload) {
    commit('SET_ERROR_SETTING_FILIALE_TH', null);
    try {
      const response = await axios.get(
        domain + `/api/filiale/delete_filiale/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_FILIALE_SETTING_TH', payload);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      return false;
    }
  },

  async updateSettingFilialeTh({ commit }, payload) {
    commit('SET_ERROR_SETTING_FILIALE_TH', null);
    const params = new FormData();
    params.append('id', payload.filiale.id);
    params.append('name', payload.filiale.name);
    if (payload.imageData) {
      params.append('logo', payload.imageData);
    }
    if (payload?.filiale?.couleur != null) {
      params.append('couleur', payload.filiale.couleur);
    }
    if (payload?.filiale?.abreviation != null) {
      params.append('abreviation', payload.filiale.abreviation);
    }
    if (payload?.filiale?.type != null) {
      params.append('type', payload.filiale.type);
    }
    if (payload?.filiale?.type_societe != null) {
      params.append('type_societe', payload.filiale.type_societe);
    }
    if (payload.filiale.prenom && payload.filiale.prenom != null) {
      params.append('prenom', payload.filiale.prenom);
    }
    if (payload?.filiale?.payment_condition != null) {
      params.append('payment_condition', payload.filiale.payment_condition);
    }
    if (payload?.filiale?.payment_type != null) {
      params.append('payment_type', payload.filiale.payment_type);
    }
    if (payload?.filiale?.start_day != null) {
      params.append('start_day', payload.filiale.start_day);
    }
    if (payload?.filiale?.start_month != null) {
      params.append('start_month', payload.filiale.start_month);
    }
    if (payload?.filiale?.start_year != null) {
      params.append('start_year', payload.filiale.start_year);
    }
    if (payload?.filiale?.end_day != null) {
      params.append('end_day', payload.filiale.end_day);
    }
    if (payload?.filiale?.end_month != null) {
      params.append('end_month', payload.filiale.end_month);
    }
    if (payload?.filiale?.end_year != null) {
      params.append('end_year', payload.filiale.end_year);
    }
    if (payload?.filiale?.pixel_id != null) {
      params.append('pixel_id', payload.filiale.pixel_id);
    }
    if (payload.filiale.footer && payload.filiale.footer != null) {
      if (payload.filiale.footer_type == 'image') {
        if (typeof payload.filiale.footer == 'object') {
          params.append('footer_type', payload.filiale.footer_type);
          params.append('footer', payload.filiale.footer);
        }
      } else {
        if (
          payload.filiale.footer_type &&
          payload.filiale.footer_type != null
        ) {
          params.append('footer_type', payload.filiale.footer_type);
        }
        params.append('footer', payload.filiale.footer);
      }
    }
    if (payload.filiale.nom_famille && payload.filiale.nom_famille != null) {
      params.append('nom_famille', payload.filiale.nom_famille);
    }
    if (payload.filiale.civilite && payload.filiale.civilite != null) {
      params.append('civilite', payload.filiale.civilite);
    }
    if (payload?.filiale?.siren_value != null) {
      params.append('siren_value', payload?.filiale?.siren_value);
    }
    if (payload?.filiale?.tva_value != null) {
      params.append('tva_value', payload?.filiale?.tva_value);
    }
    if (payload.filiale.iban && payload.filiale.iban != null) {
      params.append('iban', payload.filiale.iban);
    }
    if (payload.filiale.swift && payload.filiale.swift != null) {
      params.append('swift', payload.filiale.swift);
    }
    if (payload.filiale.code_postal && payload.filiale.code_postal != null) {
      params.append('code_postal', payload.filiale.code_postal);
    }
    if (payload?.filiale?.masterFiliales?.length) {
      for (let i = 0; i < payload?.filiale?.masterFiliales?.length; i++) {
        params.append(
          'masterFiliales[' + i + '][master_id]',
          payload?.filiale?.masterFiliales[i].master_id
        );
        params.append(
          'masterFiliales[' + i + '][date_debut]',
          payload?.filiale?.masterFiliales[i].date_debut
        );
        params.append(
          'masterFiliales[' + i + '][date_fin]',
          payload?.filiale?.masterFiliales[i].date_fin
        );
      }
    }
    if (
      payload.filiale.site_internet &&
      payload.filiale.site_internet != null
    ) {
      params.append('site_internet', payload.filiale.site_internet);
    }

    if (payload.filiale.ville && payload.filiale.ville != null) {
      params.append('ville', payload.filiale.ville);
    }
    if (payload.filiale.capital && payload.filiale.capital != null) {
      params.append('capital', payload.filiale.capital);
    }
    if (payload.filiale.rcs && payload.filiale.rcs != null) {
      params.append('rcs', payload.filiale.rcs);
    }
    if (payload.filiale.pays && payload.filiale.pays != null) {
      params.append('pays', payload.filiale.pays);
    }
    if (payload.filiale.banque && payload.filiale.banque != null) {
      params.append('banque', payload.filiale.banque);
    }
    if (payload.filiale.fax && payload.filiale.fax != null) {
      params.append('fax', payload.filiale.fax);
    }
    if (payload.filiale.telephone && payload.filiale.telephone != null) {
      params.append('telephone', payload.filiale.telephone);
    }
    if (payload.filiale.description && payload.filiale.description != null) {
      params.append('description', payload.filiale.description);
    }
    if (
      payload.filiale.telephone_portable &&
      payload.filiale.telephone_portable != null
    ) {
      params.append('telephone_portable', payload.filiale.telephone_portable);
    }
    if (
      payload.filiale.responsables &&
      payload.filiale.responsables.length > 0
    ) {
      for (let i = 0; i < payload.filiale.responsables.length; i++) {
        params.append(
          'responsables[' + i + ']',
          payload.filiale.responsables[i]
        );
      }
    }
    if (payload.filiale.siret == null) {
      params.append('siret', '');
    } else {
      params.append('siret', payload.filiale.siret);
    }
    if (payload.filiale.adresse == null) {
      params.append('adresse', '');
    } else {
      params.append('adresse', payload.filiale.adresse);
    }
    if (payload.filiale.email == null) {
      params.append('email', '');
    } else {
      params.append('email', payload.filiale.email);
    }
    if (payload.dejaVolant && payload.dejaVolant == true) {
      params.append('depots[0][id]', payload.filiale.depots[0].id);
      params.append(
        'depots[0][responsable_depot]',
        payload.filiale.depots[0].responsable_depot
      );
    } else {
      if (payload.filiale.volant && payload.filiale.volant == true) {
        params.append('volant', payload.filiale.volant);
        params.append('responsable_depot', payload.filiale.responsable_depot);
      } else {
        payload.filiale.depots.forEach((element, index) => {
          if (element) {
            params.append('depots[' + index + '][id]', element.id);
            params.append(
              'depots[' + index + '][responsable_depot]',
              element.responsable_depot
            );
          }
        });
      }
    }
    if (payload.filiale.image) {
      params.append('logo', payload.filiale.image);
    }

    if (payload.filiale.type_societe_support) {
      params.append(
        'type_societe_support',
        payload.filiale.type_societe_support
      );
    }

    if (payload.filiale.is_societe_support) {
      params.append('is_societe_support', payload.filiale.is_societe_support);
    }
    if (payload.newFiles && payload.newFiles.length > 0) {
      payload.newFiles.forEach((element, index) => {
        if (element) {
          params.append('files[' + index + '][file]', element);
          params.append('files[' + index + '][name]', element.name_file);
        }
      });
    }
    //ACHAT
    if (payload.filiale?.achat?.journal != null) {
      params.append('achat[journal]', payload.filiale?.achat.journal);
    }
    if (payload.filiale?.achat?.marchandise != null) {
      params.append('achat[marchandise]', payload.filiale?.achat.marchandise);
    }
    if (payload.filiale?.achat?.prestation != null) {
      params.append('achat[compte]', payload.filiale?.achat.prestation);
    }
    //VENTE
    if (payload.filiale?.vente?.journal != null) {
      params.append('vente[journal]', payload.filiale?.vente.journal);
    }
    if (payload.filiale?.vente?.marchandise != null) {
      params.append('vente[marchandise]', payload.filiale?.vente.marchandise);
    }
    if (payload.filiale?.vente?.prestation != null) {
      params.append('vente[compte]', payload.filiale?.vente.prestation);
    }
    //COMPTE GENERATE &
    if (payload.filiale?.customer != null) {
      params.append('customer', payload.filiale.customer);
    }
    if (payload.filiale?.supplier != null) {
      params.append('supplier', payload.filiale.supplier);
    }
    //IS DETAILED
    params.append('is_detailed', payload.filiale.is_detailed);
    params.append('is_detailed_ttc', payload.filiale.is_detailed_ttc);
    params.append(
      'is_detailed_reste_charge',
      payload.filiale.is_detailed_reste_charge
    );

    //PRIME
    if (payload.filiale?.prime?.compte != null) {
      params.append('prime[compte]', payload.filiale.prime.compte);
    }
    if (payload.filiale?.prime?.journal != null) {
      params.append('prime[journal]', payload.filiale.prime.journal);
    }

    if (payload.filiale?.tva && payload.filiale?.tva.length) {
      for (let i = 0; i < payload.filiale?.tva.length; i++) {
        params.append('tva[' + i + '][valeur]', payload.filiale?.tva[i].valeur);
        params.append(
          'tva[' + i + '][compte_vente]',
          payload.filiale?.tva[i].compte_vente == null ||
            payload.filiale?.tva[i].compte_vente == ''
            ? null
            : payload.filiale?.tva[i].compte_vente
        );
        params.append(
          'tva[' + i + '][compte_achat]',
          payload.filiale?.tva[i].compte_achat == null ||
            payload.filiale?.tva[i].compte_achat == ''
            ? null
            : payload.filiale?.tva[i].compte_achat
        );
        params.append(
          'tva[' + i + '][is_active]',
          payload.filiale?.tva[i].is_active
        );
        params.append(
          'tva[' + i + '][compte_marchandise]',
          payload.filiale?.tva[i].compte_marchandise
        );
        params.append(
          'tva[' + i + '][compte_prestation]',
          payload.filiale?.tva[i].compte_prestation
        );
      }
    }
    if (payload.dataRefresh && payload.dataRefresh.refresh_file) {
      params.append('refresh_file', true);
      if (payload.dataRefresh.date_debut) {
        params.append('date_debut', payload.dataRefresh.date_debut);
      }
      if (payload.dataRefresh.date_fin) {
        params.append('date_fin', payload.dataRefresh.date_fin);
      }
      if (payload.dataRefresh.ids) {
        for (let i = 0; i < payload.dataRefresh.ids.length; i++) {
          params.append('ids[' + i + ']', payload.dataRefresh.ids[i]);
        }
      }
      if (payload.dataRefresh.client_id?.length) {
        for (let i = 0; i < payload.dataRefresh.client_id.length; i++) {
          params.append(
            'client_id[' + i + ']',
            payload.dataRefresh.client_id[i]
          );
        }
      }
      if (payload.dataRefresh.type_facture?.length) {
        for (let i = 0; i < payload.dataRefresh.type_facture.length; i++) {
          params.append(
            'type_facture[' + i + ']',
            payload.dataRefresh.type_facture[i]
          );
        }
      }
    }
    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/filiale/edit_filiale`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('UPDATE_FILIALE_SETTING', {
        old: payload.oldFiliale,
        response: response.data.data
      });
      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return {
          success: false,
          error: data
        };
      } else if (error?.response?.data?.error) {
        return {
          success: false,
          error: error?.response?.data?.error
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },

  async fetchResponsableFilialeFreeTh({ commit }) {
    commit('SET_ERROR_SETTING_FILIALE_TH', null);
    try {
      const response = await axios.get(
        domain + `/api/filiale/free_responsable`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_SETTING_RESPONSABLE_FILIALE_TH', response.data.data);
      // commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },

  async getAllTypeSocieteSupport({ commit }) {
    state.typeSocieteSupportth = [];
    try {
      const response = await axios.get(domain + `/api/type-societe-support`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });

      commit('SET_SETTING_TYPE_SOCIETE_SUPPORT', response.data.data);

      return true;
    } catch (error) {
      return false;
    }
  },

  async removeFilialeFile({ commit }, payload) {
    try {
      const response = await axios.delete(
        domain + `/api/filiale/delete-file/` + payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  },
  async renameFilialeFile({ commit }, payload) {
    const params = {
      name: payload.newName
    };

    try {
      if (requestRenameFile) {
        requestRenameFile.cancel('cancel-request');
      }
      requestRenameFile = axios.CancelToken.source();
      const response = await axios.post(
        domain + `/api/filiale/rename-file/` + payload.file.id,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: requestRenameFile.token
        }
      );

      const obj = {
        response: response.data.data,
        item: payload.item,
        index: payload.index
      };

      commit('SET_FILE_FILIALE_NAME', obj);

      return true;
    } catch (error) {
      return false;
    }
  },
  async getAllFilialesListe({ commit }) {
    try {
      if (cancelRequestAllFilialeListe) {
        cancelRequestAllFilialeListe.cancel('cancel-request');
      }
      cancelRequestAllFilialeListe = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/filiale/all_filiale`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        cancelToken: cancelRequestAllFilialeListe.token
      });
      commit('SET_FILIALE_LOCATEUR', response.data.data);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_LISTE_SOCIETE_SUPPORT', error.response.data);
      } else {
        commit('SET_ERROR_LISTE_SOCIETE_SUPPORT', 'Une erreur est survenue');
      }
      return false;
    }
  },
  async fetchSequenceFactureFields({ commit }) {
    try {
      const response = await axios.get(`${domain}/api/filiale/variables`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_SEQUENCE_FACTURE_FIELDS', response.data);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_ERROR_LISTE_SOCIETE_SUPPORT',
          error.response.data.error.messages
        );
      } else {
        commit('SET_ERROR_LISTE_SOCIETE_SUPPORT', ['Une erreur est survenue']);
      }
      return false;
    }
    return true;
  },
  async getSettingOrgenismeTh({ commit }, payload) {
    const params = { type: 'organisme' };

    commit('SET_ERROR_SETTING_FILIALE_TH', null);
    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    try {
      if (cancelRequestSettingOrganismeTh) {
        cancelRequestSettingOrganismeTh.cancel('cancel-request');
      }
      cancelRequestSettingOrganismeTh = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/filiale/all_filiale`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: cancelRequestSettingOrganismeTh.token
      });

      commit('SET_SETTING_ORGANISME_TH', response.data.data);

      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  async getSettingFilialeThNew({ commit }) {
    commit('SET_ERROR_SETTING_FILIALE_TH', null);

    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    try {
      const response = await axios.get(domain + `/api/libre/filiales`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_SETTING_FILIALE_TH', response.data);
      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  transformFormatObjetClientPassage({ commit }, payload) {
    commit('NEW_SETTING_FILIALE_TH', payload);
  },
  async exportListFilialeTh({ commit }, payload) {
    try {
      const response = await axios.get(domain + `/api/filiale/export_filiale`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.state.User.access_token}`,
        },
        params: payload

      });
      this.state.showNotifExportFile = true;
      return { success: true, msg: response.data };
    } catch (error) {
      return { success: false };
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
