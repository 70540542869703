import Vue from 'vue'

import { library, config } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
// import { dom } from '@fortawesome/fontawesome-svg-core'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
// Configure Font Awesome with your private token
config.autoAddCss = false
config.api = {
    apiKey: '93090F35-DFCF-47B1-8F44-D3D714F8FB9F'
}
// IMPORTATION FREE ICON 

import {
    faAngleRight,
    faEuroSign,
    faFileMedical,
    faUserSlash,
    faCloudUploadAlt,
    faBug,
    faEdit,
    faWindowClose,
    faPlus,
    faPencilAlt,
    faTrashAlt,
    faMinus,
    faSyncAlt,
    faBars,
    faUserCircle,
    faLongArrowAltLeft,
    faCheck,
    faDownload,
    faTimes,
    faInfo,
    faFilePdf,
    faSearch,
    faCircleNotch,
    faCloudDownloadAlt,
    faPen,
    faClone,
    faAt,
    faClosedCaptioning,
    faHouseUser,
    faAlignJustify,
    faRetweet,
    faClipboardCheck,
    faEllipsisH,
    faFileExcel,
    faFolder,
    faEnvelope,
    faArrowCircleDown,
    faExclamationCircle,
    faWrench,
    faFileInvoice,
    faFileInvoiceDollar,
    faFile,
    faArrowRight,
    faPhone,
    faBatteryEmpty,
    faPlusCircle,
    faCamera,
    faClipboardList,
    faRecycle
} from '@fortawesome/free-solid-svg-icons';
import { faBell, faCircle } from '@fortawesome/free-regular-svg-icons';
// END INPORTATION FREE ICON 
library.add(
    fal, fab, fad, fat,
    far,
    fas,
    faCloudUploadAlt,
    faBug,
    faEdit,
    faWindowClose,
    faPlus,
    faPencilAlt,
    faTrashAlt,
    faMinus,
    faSyncAlt,
    faBars,
    faUserCircle,
    faLongArrowAltLeft,
    faCheck,
    faDownload,
    faTimes,
    faInfo,
    faFilePdf,
    faSearch,
    faCircleNotch,
    faBell,
    faCircle,
    faCloudDownloadAlt,
    faFileMedical,
    faUserSlash,
    faPen,
    faAngleRight,
    faEuroSign,
    faClone,
    faAt,
    faClosedCaptioning,
    faHouseUser,
    faAlignJustify,
    faRetweet,
    faClipboardCheck,
    faFileExcel,
    faFolder,
    faEnvelope,
    faArrowCircleDown,
    faExclamationCircle,
    faWrench,
    faFileInvoice,
    faFileInvoiceDollar,
    faFile,
    faArrowRight,
    faPhone,
    faBatteryEmpty,
    faPlusCircle,
    faEllipsisH,
    faCamera,
    faClipboardList,
    faRecycle
);
// dom.watch()
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.component('font-awesome-icon', FontAwesomeIcon)
