import axios from 'axios';
import domain from '@/environment';
import parametreSpecifique from '../../models/parametreSpecifique';
const state = {
  parametreFixeOrganisme: [],
  parametreFixeOrganismeLoading: false,
  parametreFixeOrganismeError: []
};
const getters = {
  parametreFixeOrganisme: state => state.parametreFixeOrganisme,
  parametreFixeOrganismeLoading: state => state.parametreFixeOrganismeLoading,
  parametreFixeOrganismeError: state => state.parametreFixeOrganismeError
};
const mutations = {
  SET_PARAMETRE_SPECIFIQUE_ERROR(state, payload) {
    state.parametreFixeOrganismeError = payload;
  },
  ADD_NEW_PARAMETRE_SPECIFIQUE(state, payload) {
    state.parametreFixeOrganisme.unshift(parametreSpecifique.create(payload));
  },
  SET_PARAMETRE_SPECIFIQUE_ORGANISME(state, payload) {
    state.parametreFixeOrganisme = payload.map(parametre =>
      parametreSpecifique.create(parametre)
    );
  },
  SET_LOADER_PARAMETRE_SPECIFIQUE(state, payload) {
    state.parametreFixeOrganismeLoading = payload;
  },
  DELETE_PARAMETRE_SPECIFIQUE(state, payload) {
    let index = state.parametreFixeOrganisme.findIndex(
      item => item.id == payload
    );
    state.parametreFixeOrganisme.splice(index, 1);
  },
  UPDATE_PARAMETRE_ORGANISME(state, payload) {
    payload.oldParametre.update(payload.parametre);
  }
};
const actions = {
  async fetchAllParametreSpecifique({ commit }, payload) {
    commit('SET_PARAMETRE_SPECIFIQUE_ERROR', null);
    commit('SET_LOADER_PARAMETRE_SPECIFIQUE', true);
    try {
      const response = await axios.get(
        domain + '/api/config_obligee_specifique/index',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_PARAMETRE_SPECIFIQUE_ORGANISME', response.data.data);
      commit('SET_LOADER_PARAMETRE_SPECIFIQUE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_LOADER_PARAMETRE_SPECIFIQUE', false);
        commit('SET_PARAMETRE_SPECIFIQUE_ERROR', error.response.data.error);
      } else {
        commit('SET_LOADER_PARAMETRE_SPECIFIQUE', false);
        commit('SET_PARAMETRE_SPECIFIQUE_ERROR', 'Une erreur est survenue');
      }
    }
  },
  async addNewParametreSpecfique({ commit }, payload) {
    commit('SET_PARAMETRE_SPECIFIQUE_ERROR', null);
    try {
      const response = await axios.post(
        domain + '/api/config_obligee_specifique/create',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_PARAMETRE_SPECIFIQUE', response.data.data);
      return { success: true, data: response.data.data };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_PARAMETRE_SPECIFIQUE_ERROR', data);
        return {
          success: false
        };
      } else if (error?.response?.data?.error) {
        commit('SET_PARAMETRE_SPECIFIQUE_ERROR', error?.response?.data?.error);
        return {
          success: false
        };
      }

      else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async updateParametreSpecifique({ commit }, payload) {
    commit('SET_PARAMETRE_SPECIFIQUE_ERROR', null);
    try {
      const response = await axios.post(
        domain + '/api/config_obligee_specifique/update',
        payload.parametre,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_PARAMETRE_ORGANISME', {
        parametre: response.data.data,
        oldParametre: payload.oldParametre
      });
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_PARAMETRE_SPECIFIQUE_ERROR', data);
        return {
          success: false
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async deleteParametreSpecifique({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/config_obligee_specifique/delete/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_PARAMETRE_SPECIFIQUE', payload);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_PARAMETRE_SPECIFIQUE_ERROR', error.response.data.error);
      } else {
        commit('SET_PARAMETRE_SPECIFIQUE_ERROR', 'Une erreur est survenue');
      }
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
