import axios from 'axios';

const state = {
    menuIla26: [{
        title: 'MODULES',
        sous_menu: [
            { title: 'Busniss Map', icon: ['fal', 'map-location-dot'], url: 'http://localhost:3001/cdg' },
            { title: 'Marketplace', icon: ['fal', 'store'], url: 'http://localhost:3001/rapportTh' },
            { title: 'Gestion des taches', icon: ['fal', 'square-list'], url: 'http://localhost:3001/frais-th/simulation-facture-frais' },
            { title: 'Calendrier partagé', icon: ['fal', 'calendar-clock'], url: 'http://localhost:3001/frais-th/simulation-facture-frais' },
            { title: 'Inbox', icon: ['fat', 'message-lines'], url: 'http://localhost:3001/comptabilite' },
            { title: 'Base de connaissances', icon: ['fal', 'server'], url: 'http://localhost:3001/reglement-facture' },
        ]
    },
    {
        title: 'PERSONNELLE',
        sous_menu: [{
            title: 'Perso Lite', icon: ['fal', 'square-share-nodes'], url: 'http://localhost:3001/refresh-fature'
        }]
    },
    {
        title: 'PARAMETRAGES',
        sous_menu: [{
            title: 'Mon profil', icon: ['fal', 'square-user'], url: 'http://localhost:3001/setting'
        }]
    }],
    menuIla26Error: null,
    menuIla26Loader: null,
}
const getters = {
    getMenuIla26: state => state.menuIla26,
    getMenuIla26Error: state => state.menuIla26Error,
    getMenuIla26Loader: state => state.menuIla26Loader
}
const mutations = {
    SET_MENU_ILA26(state, payload) {
        state.menuIla26 = payload
    },
    SET_ERROR_MENU_ILA26(state, payload) {
        state.menuIla26Error = payload
    },
    SET_LOADER_MENU_ILA26(state, payload = false) {
        state.menuIla26Loader = payload
    },
}
const actions = {
    async fetchMenuIla26Liste({ commit }) {
        commit('SET_MENU_ILA26', true)
        try {
            const response = await axios.get('url');
            commit('SET_MENU_ILA26', response.data);
            commit('SET_MENU_ILA26')
            return true
        } catch {
            commit('SET_ERROR_MENU_ILA26', "Une erreur est servenue lors de l arécuperation");
            commit('SET_MENU_ILA26')
            return false
        }
    }
}


export default {
    state, getters, mutations, actions
}